import React from 'react'
import Image from 'next/image'
import placeholder from 'src/assets/images/profile-placeholder.svg'
import { ImageProps } from 'next/dist/shared/lib/get-img-props'

type Props = ImageProps & {
  fallback?: string
  onError?: () => void
}

const IMAGES_HOSTNAME =
  process.env.IMAGE_REMOTE_PATTERN_HOSTNAME || 'imgs.socialprofiler.com'

const FallBackImage = ({
  fallback = placeholder,
  src,
  alt,
  width,
  height,
  onError,
  ...props
}: Props) => {
  const [error, setError] = React.useState(false)

  if (
    src &&
    src !== '' &&
    typeof src === typeof '' &&
    `${src}`.indexOf('http') === -1
  ) {
    src = `https://${IMAGES_HOSTNAME}/${src}`
  }

  React.useEffect(() => {
    setError(false)
  }, [src])

  const onImageError = React.useCallback(() => {
    setError(true)
    if (onError) {
      onError()
    }
  }, [onError])

  return (
    <Image
      alt={alt}
      onError={onImageError}
      src={error || !src ? fallback : src}
      width={width}
      height={0}
      quality={90}
      {...props}
    />
  )
}

export default FallBackImage
