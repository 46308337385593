import React from 'react'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import { useRouter } from 'next/navigation'
import type { User } from '@/lib/api-client/generated'
import { useAuthInfoService } from '@/lib/hooks/api'
import {
  getCookieDomain,
  SESSION_COOKIE_NAME,
  SESSION_VERSION,
} from '@/utils/cookie'
import { setUserId } from '@/lib/analytics/ga4'

const SESSION_VER_COOKIE_NAME = `${SESSION_COOKIE_NAME}_version`
const sessionCookiesMaxAge = 60 * 60 * 24 * 30

/**
 * Check for session token in cookie and stored version
 */
const isSessionActive = () => {
  const session = getCookie(SESSION_COOKIE_NAME)
  const sessionVersion = getCookie(SESSION_VER_COOKIE_NAME)

  return !!(
    session &&
    sessionVersion &&
    Number(sessionVersion) === SESSION_VERSION
  )
}

type AuthContext = {
  isAuthenticated: boolean
  getUser: () => Promise<User> | null
  setSession: (token: string) => void
  closeSession: () => void
  user?: User
}

const initialValue = {
  isAuthenticated: false,
  getUser: () => null,
  setSession: () => null,
  closeSession: () => null,
}

const AuthContext = React.createContext<AuthContext>(initialValue)

export const useAuthContext = () => React.useContext(AuthContext)

export const AuthProvider: React.FC<{
  children: (isLoading: boolean) => JSX.Element
}> = ({ children }) => {
  const router = useRouter()
  const [{ value: user, loading }, getAuthInfo, reset] = useAuthInfoService()
  const [userLoadingFired, setUserLoadingFired] = React.useState<boolean>(false)

  const setSession = React.useCallback((token: string) => {
    setCookie(SESSION_COOKIE_NAME, token, {
      domain: getCookieDomain(),
      maxAge: sessionCookiesMaxAge,
    })
    setCookie(SESSION_VER_COOKIE_NAME, SESSION_VERSION, {
      domain: getCookieDomain(),
      maxAge: sessionCookiesMaxAge,
    })

    router.refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeSession = React.useCallback(() => {
    deleteCookie(SESSION_COOKIE_NAME)
    deleteCookie(SESSION_VER_COOKIE_NAME)

    reset()

    router.push('/login')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const value = React.useMemo(
    () => ({
      isAuthenticated: isSessionActive(),
      getUser: getAuthInfo,
      setSession,
      closeSession,
      user,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, loading],
  )

  /** Get user info on app mount and active session. */
  React.useEffect(() => {
    if (isSessionActive() && !user && !loading) {
      setUserLoadingFired(true)
      getAuthInfo()
    } else if (user) {
      setUserId(user.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const isLoading = !userLoadingFired || loading

  return (
    <AuthContext.Provider value={value}>
      {children?.(isLoading)}
    </AuthContext.Provider>
  )
}
