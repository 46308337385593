import React from 'react'
import type { CancelablePromise, GeneralError } from '../api-client/generated'
import useBrowserBackButtonEffect from './use-browser-back-button-effect'

const useCancelablePromise = <
  Response,
  T extends CancelablePromise<Response | GeneralError> = CancelablePromise<
    Response | GeneralError
  >,
>() => {
  const promiseRef = React.useRef<T | null>(null)

  const cancel = React.useCallback(() => {
    promiseRef.current?.cancel()
  }, [])

  const addToCancel = React.useCallback((promise: T) => {
    promiseRef.current = promise

    return promise
  }, [])

  useBrowserBackButtonEffect(() => {
    cancel()

    return true
  })

  return [addToCancel, cancel] as const
}

export default useCancelablePromise
