import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { useRouter } from 'next/router'

import { useTranslation } from 'react-i18next'

import logo from 'src/assets/images/logo.svg'

import styles from './Logo.module.scss'
import { sendEvent } from '@/lib/analytics/ga4'

const Logo = () => {
  const { t } = useTranslation()
  const { pathname, query } = useRouter()

  const redirectLogoPath =
    pathname === '/login'
      ? `${process.env.NEXT_PUBLIC_LANDING_URL}`
      : `/dashboard${query.id ? `#${query.id}` : ''}`

  return (
    <Link
      className={styles.link}
      href={redirectLogoPath}
      onClick={() => sendEvent({ action: 'logoClick', category: 'header' })}
    >
      <Image
        className={styles.logo}
        src={logo}
        alt={t('logoText')}
        style={{ maxHeight: 'unset' }}
        priority
      />
    </Link>
  )
}

export default Logo
