import ga4 from 'react-ga4'
import { UaEventOptions } from 'react-ga4/types/ga4'
import { isDev } from '../runtime'
import { GA_TRACKING_ID } from '@/constants/config'

const TRACKING_ID = GA_TRACKING_ID

export const init = () => {
  ga4.initialize(TRACKING_ID, {
    testMode: isDev,
  })
}

export const setUserId = (userId: string) => {
  ga4.set({ user_id: userId })
}

export const sendEvent = (event: UaEventOptions) => {
  ga4.event(event)
}

export const sendPageview = (path: string) => {
  ga4.send({
    hitType: 'pageview',
    page: path,
  })
}
