import { useTranslation } from 'react-i18next'
import styles from './Error.module.scss'
import { cx } from 'class-variance-authority'

type ErrorSectionProps = {
  errorCode: '404' | '403' | 'maintenance'
}

export const ErrorSection: React.FC<ErrorSectionProps> = ({ errorCode }) => {
  const { t } = useTranslation('', { keyPrefix: 'notFound' })

  return (
    <div className={cx([styles.container, styles[errorCode]])}>
      <h2>{t(`${errorCode}.title`)}</h2>
      <p>{t(`${errorCode}.message`)}</p>
    </div>
  )
}
