'use client'

import React from 'react'
import { Logo } from './Logo'
import { useRenderOnMount } from '@/lib/hooks'
import { Navigation } from './Navigation'

import styles from './Header.module.scss'
import { Button, Icon } from '@/components'
import Link from 'next/link'
import { useAuthContext } from '@/context/AuthContext'
import { useMountedState } from 'react-use'
import { t } from 'i18next'

const Header: React.FC = React.memo(() => {
  const isMaintenance = !!process.env.NEXT_PUBLIC_MAINTENANCE
  const { isAuthenticated } = useAuthContext()
  const isMounted = useMountedState()

  useRenderOnMount()

  return (
    <div
      className={styles.header}
      role="navigation"
      aria-label="main navigation"
    >
      <div className={styles.header__brand}>
        <div>
          <Logo />
        </div>
        {!isMaintenance && (
          <>
            <Navigation />
            {isAuthenticated && isMounted() && (
              <Button
                type="button"
                size="icon"
                className={styles.settings}
                title={t('hints.settings')}
              >
                <Link href="/settings" className={styles.settings_link}>
                  <Icon
                    name="settings"
                    width={24}
                    height={24}
                    className={styles.settings_icon}
                  />
                </Link>
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  )
})

export default Header
