/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FollowsCheck } from '../models/FollowsCheck'
import type { GeneralError } from '../models/GeneralError'
import type { InterestId } from '../models/InterestId'
import type { InterestProfiles } from '../models/InterestProfiles'
import type { RedzoneCategory } from '../models/RedzoneCategory'
import type { ReportBlured } from '../models/ReportBlured'
import type { ReportGeneratedCheck } from '../models/ReportGeneratedCheck'
import type { ReportId } from '../models/ReportId'
import type { ReportItem } from '../models/ReportItem'
import type { ReportSingleInterest } from '../models/ReportSingleInterest'
import type { ReportSummary } from '../models/ReportSummary'
import type { Source } from '../models/Source'
import type { SummaryCheck } from '../models/SummaryCheck'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class DataReceivingService {
  /**
   * @param source source of report
   * @returns RedzoneCategory list of all available interests
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getRedzoneInterests(
    source: Source,
  ): CancelablePromise<Array<RedzoneCategory> | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/interests/{source}/redzone',
      path: {
        source: source,
      },
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @returns ReportGeneratedCheck Check if report is generated
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getCheck(
    reportId: ReportId,
  ): CancelablePromise<ReportGeneratedCheck | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/check/{reportId}',
      path: {
        reportId: reportId,
      },
      errors: {
        400: `error on uncompleted report request`,
        401: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param limit items to get
   * @returns any Array of interests with profiles based on preview
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getReport(
    reportId: ReportId,
    limit: number = 8,
  ): CancelablePromise<
    | {
        profiles: Array<ReportItem>
        summary?: ReportSummary
        public?: boolean
        blured?: ReportBlured
        tracked?: number
      }
    | GeneralError
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report/{reportId}',
      path: {
        reportId: reportId,
      },
      query: {
        limit: limit,
      },
      errors: {
        400: `error on uncompleted report request`,
        401: `response for error`,
        402: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param source source of report
   * @param limit items to get
   * @returns ReportItem Interests with profiles based on preview for worksource
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getReport1(
    reportId: ReportId,
    source: Source,
    limit: number = 8,
  ): CancelablePromise<ReportItem | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report/{reportId}/{source}',
      path: {
        reportId: reportId,
        source: source,
      },
      query: {
        limit: limit,
      },
      errors: {
        400: `error on uncompleted report request`,
        401: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @returns ReportSummary GPT Generated summary of report
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getReportSummary(
    reportId: ReportId,
  ): CancelablePromise<ReportSummary | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report/{reportId}/summary',
      path: {
        reportId: reportId,
      },
      errors: {
        400: `error on uncompleted report request`,
        401: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param source source of report
   * @param interestId id of interests
   * @param limit items to get
   * @returns ReportSingleInterest list of profiles on which interest was found and additional info
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getReport2(
    reportId: ReportId,
    source: Source,
    interestId: InterestId,
    limit: number = 8,
  ): CancelablePromise<ReportSingleInterest | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report/{reportId}/{source}/{interestId}',
      path: {
        reportId: reportId,
        source: source,
        interestId: interestId,
      },
      query: {
        limit: limit,
      },
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @param source source of report
   * @param interestId id of interests
   * @param offset items to skip
   * @param limit items to get
   * @returns InterestProfiles page of profiles list on which interest was found
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getReportProfiles(
    reportId: ReportId,
    source: Source,
    interestId: InterestId,
    offset?: number,
    limit: number = 8,
  ): CancelablePromise<InterestProfiles | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report/{reportId}/{source}/{interestId}/profiles',
      path: {
        reportId: reportId,
        source: source,
        interestId: interestId,
      },
      query: {
        offset: offset,
        limit: limit,
      },
      errors: {
        401: `response for error`,
      },
    })
  }

  /**
   * @param reportId string ID of report
   * @returns any A continuous stream of server-sent events.
   *
   * @returns GeneralError response for error
   * @throws ApiError
   */
  public static getSse(
    reportId: ReportId,
  ): CancelablePromise<(FollowsCheck | SummaryCheck) | GeneralError> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sse/{reportId}',
      path: {
        reportId: reportId,
      },
    })
  }
}
