export const COLLAPSED_INTERESTS_COUNT = 256
export const CATEGORY_OTHER_ID = 50
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID ?? 'dummy-ga-id'

/** Default number of profiles to get from server. */
export const DEFAULT_PROFILES_LIMIT = 24

/** API Mocks toggle */
export const MOCKED_API = process.env.NEXT_PUBLIC_MOCKED_API === 'true'

export const RECAPTCHA_SITEKEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY

export const EXAMPLES_CACHE_LIFETIME = 86400
export const STATES_CACHE_LIFETIME = 86400
export const REPORTS_LIST_CACHE_LIFETIME = 300
