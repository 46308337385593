import React from 'react'

import { useMount } from 'react-use'

import { useAuthContext } from '@/context/AuthContext'
import { useSendCookieConsentService } from '@/lib/hooks'
import CookiesModal from './ui/CookiesModal'
import { getCookie, setCookie } from 'cookies-next'
import { getCookieDomain } from '@/utils/cookie'
import { sendEvent } from '@/lib/analytics/ga4'

const CookieConsent: React.FC = React.memo(() => {
  const { isAuthenticated } = useAuthContext()

  const [show, setShow] = React.useState(false)

  const [, sendCookieConsent] = useSendCookieConsentService()

  const handleConsentClick = async (consent: boolean) => {
    setShow(false)

    sendEvent({
      action: consent ? 'accepted' : 'declined',
      category: 'cookiesConsent',
    })

    const response = await sendCookieConsent()

    if (response.status === 'success') {
      setCookie('consent', consent, {
        domain: getCookieDomain(),
        maxAge: 60 * 60 * 24 * 365,
      })
    }
  }

  /** Check if user is not authenticated and consent decision cookie is exists - request cookie consent info. */
  useMount(() => {
    const hasConsentDecision = getCookie('consent')

    if (!hasConsentDecision && !isAuthenticated) {
      setShow(true)
    }
  })

  if (show) {
    return <CookiesModal onClick={handleConsentClick} />
  }

  return null
})

export default CookieConsent
