import type { ProfileId, Source } from '@/lib/api-client/generated'
import { isGeneralError } from '@/utils/api'
import useCustomAsyncFn from '../use-custom-async-fn'
import apiClient from '@/lib/api-client'
import useErrorEffect from '../../use-error-effect'

type PostReportAdd = {
  source: Source
  id?: ProfileId
  unloaded?: boolean
  imageUnloaded?: boolean
}

/**
 * Service for adding profile to report creation.
 */
const useAddSourceToReportService = (reportId: string) => {
  const state = useCustomAsyncFn(
    async ({ source, unloaded, id, imageUnloaded }: PostReportAdd) => {
      const result = await apiClient.ReportCreation.postCreateAdd(
        reportId,
        source,
        { id, unloaded, imageUnloaded },
      )

      if (isGeneralError(result)) {
        throw result
      }

      return result
    },
    [],
  )

  useErrorEffect(state[0].value, state[0].error)

  return state
}

export default useAddSourceToReportService
