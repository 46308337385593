import React from 'react'

import styles from './ModalOverlay.module.scss'

type ModalOverlayProps = React.PropsWithChildren & {
  onCloseModal: () => void
}

const ModalOverlay: React.FC<ModalOverlayProps> = ({ children }) => {
  return (
    <div className={styles.modal}>
      <div className={styles.modal__overlay} />

      {children}
    </div>
  )
}

export default ModalOverlay
